import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";
import { ArrowsUpDownIcon } from "@heroicons/react/16/solid";
import Logo from "../../assets/img/logo.png";

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flex justify-between" style={{ padding: "30px 0" }}>
            <div className="flex justify-start flex-col">
              <Link className="flex items-center mb-2 animate pointer" to="home" smooth={true} offset={-80}>
                <LogoImg />
                <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                  {/* <img className="radius8 w-[80px]" src={Logo} alt="add" /> */}
                  MrClean
                </h1>
              </Link>
              <StyleP className="whiteColor font13">
                © {getCurrentYear()} - <span className="purpleColor pointer font13">MrClean</span> Tout droit réserver.
              </StyleP>
            </div>
            <div className="whiteColor">
              <p className="font-bold mb-2">Contact & Adresse</p>
              <p className="font14"><a href="tel:+243850665379" className="text-white"> +243 850 665 379</a></p>
              <p className="font14">Av. Muswalu numéro 15</p>
              <p className="font14">Commune lemba / Quartier salongo</p>
            </div>

            <Link className=" fixed bottom-5 right-5 hover:text-slate-100 text-slate-100 bg-red-500 rounded flex justify-center items-center w-5 h-6" to="home" smooth={true} offset={-80}>
              <ArrowsUpDownIcon/>
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;