import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import { Modal } from 'flowbite-react'
import React from 'react'
import { Link } from 'react-router-dom'
import data from '../constants/data'

const TypeAbonementList = () => {
  return (
    <>
      <Modal.Body>
        <h3 className="text-1xl mb-3 font-medium">
            Liste des abonnement
        </h3>
        <div className="relative max-h-[60vh] z-[9999] overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="p-1">
                  <div className="flex items-center">
                      No.
                  </div>
                </th>
                <th scope="col" className="px-3 py-1">
                  Libellé
                </th>
                <th scope="col" className="px-3 py-1">
                    
                </th>
              </tr>
            </thead>
            <tbody>
              {
                data.typeAbonements.map((typeAbonement, idx) => (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="w-4 p-4">
                        <span className="p-1">{idx+=1}</span>
                    </td>
                    <th scope="row" className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <span className="px-1 py-[2px] rounded text-slate-800 font-semibold bg-gray-300">{ typeAbonement.name }</span>
                    </th>
                    
                    {/* <td className="flex justify-center items-center px-3 py-1">
                        <Link className="rounded font-medium  bg-blue-600 hover:bg-blue-800 durarion-300 hover:underline p-[5px]"><TrashIcon className="w-2 h-2 text-slate-100"/></Link>
                        <Link className="rounded font-medium text-red-50 bg-red-600 dark:text-red-500 hover:underline ms-3 p-[5px]"><PencilSquareIcon className="w-2 h-2 rounded text-slate-100"/></Link>
                    </td> */}
                  </tr>
                ))
              }
              
            </tbody>
          </table>
        </div> 
      </Modal.Body>
    </>
  )
}

export default TypeAbonementList