import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  const [datafetch, datafetchSet] = useState([])

  useEffect(() => {
    async function fetchTypeAbonnements() {
      let response = await fetch('https://apimrclean.onrender.com/typeabonnements')
      response = await response.json()
      datafetchSet(response)
    }
    fetchTypeAbonnements()
  }, [])

  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Abonnement</h1>
            <p className="font13">
              Nos tarification sont abordable et accéssible par tous le monde dépendement type d'abonnement que vous voulez prendre.
              <br />
              Vous aussi pouvez prendre votre abonnnement de maintenant.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            {
              datafetch.map((item) => {
                return(
                  <TableBox>
                    <PricingTable
                      icon="browser"
                      price={ item.price +"$ / mois"}
                      title={ item.name}
                      text={ item.description}
                      offers={[
                        { name:  item.period , cheked: true },
                      ]}
                    />
                  </TableBox>
                )
              })
            }
            {/* <TableBox>
              <PricingTable
                icon="monitor"
                price="499,99$/mois"
                title="Standard"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "2 fois/sémaines", cheked: true },
                ]}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="599,99$/mois"
                title="Premium/VIP"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Tous le jours", cheked: true },
                ]}
              /> 
            </TableBox>*/}
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




