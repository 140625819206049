import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import axios from 'axios'
import { Badge, Modal } from 'flowbite-react'
import React from 'react'
import { Link } from 'react-router-dom'

const EquipeList = ({ TeamUsers = [], ModalTeamList}) => {

    const deleteTeamUser = (teamUser) => {
        axios.delete('teamusers/'+ teamUser)
        .then(async(response) =>{
            // let teamUsers = TeamUsers.filter(val => val.id != teamUser)
            // TeamUsers = teamUsers
            ModalTeamList(teamUser?.team)
        }).catch((error) => {
            
        })
    }
    return (
        <>
            <>
                <Modal.Header className=" pb-[0] px-4 pt-4">
                    <h3 className="text-1xl mb-3 font-medium">
                        Membres de l'équipe
                    </h3>
                </Modal.Header>
                <Modal.Body className="py-1">

                    <div className="relative max-h-[60vh] z-[9999] overflow-x-auto overflow-y-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="p-1">
                                        <div className="flex items-center">
                                            No.
                                        </div>
                                    </th>
                                    <th scope="col" className="px-3 py-1">
                                        Nom & prénom
                                    </th>
                                    <th scope="col" className="px-3 py-1">
                                        Post
                                    </th>
                                    <th scope="col" className="px-3 py-1">
                                        Email
                                    </th>
                                    <th scope="col" className="px-3 py-1">
                                        Num. Phone
                                    </th>

                                    <th scope="col" className="px-3 py-1">
                                        Adresse
                                    </th>
                                    <th scope="col" className="px-3 py-1">
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    TeamUsers?.map((teamuser, index) => {
                                        return(
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-3 py-1">
                                                <span>{ index += 1}</span>
                                            </td>
                                            <th scope="row">
                                                <Link to="/admin/profile" className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    { teamuser.user?.name}
                                                </Link>
                                            </th>
                                            <td className="px-3 py-1">
                                            { teamuser.user?.profile?.name}
                                            </td>
                                            <td className="px-3 py-1">
                                                { teamuser.user?.email}
                                            </td>
                                            <td className="px-3 py-1">
                                                { teamuser.user?.tel}
                                            </td>
                                            <td className="px-3 py-1">
                                                { teamuser.user?.adress}
                                            </td>
                                            <td className="flex justify-center items-center px-3 py-1">
                                                <Link onClick={() => deleteTeamUser(teamuser.id)} className="rounded font-medium text-red-50 bg-red-600 dark:text-red-500 hover:underline ms-3 p-[5px]"><TrashIcon className="w-2 h-2 text-slate-100"/></Link>
                                            </td>
                                        </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div> 
                </Modal.Body>
            </>
        </>
    )
}

export default EquipeList