import React from "react";

const PasswordForm = () => {
    return (
        <>      
            <div className="space-y-2">
                <h4 className="text-xl font-medium">Mot de passe</h4>
                <form className="px-1 py-2 shadow-lg">
                    <div className="grid gap-2 mb-6 md:grid-cols-1">
                        <div>
                            <label for="passeword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mot de passe</label>
                            <input type="password" id="passeword" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                        </div>
                        <div>
                            <label for="confirm_password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prénom</label>
                            <input type="password" id="confirm_password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                        </div>
                    </div>
                    
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center">Modifier</button>
                </form>
            </div>
        </>
    )
}

export default PasswordForm;