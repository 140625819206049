import { EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ClientList = ({ clients, editClient }) => {

    const handleCheck = (val) => {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    return (
        <>
            <section>
                <h3 className="text-xl mb-3 font-medium">
                    Liste de clients
                </h3>
                <div className="relative max-h-[60vh] overflow-x-auto overflow-y-auto ">
                    <table className="w-full text-sm text-left rtl:text-right ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-1">
                                    <div className="flex items-center">
                                        No.
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Client
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Pesonne de contact
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Email
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Num. Phone
                                </th>

                                <th scope="col" className="px-3 py-1">
                                    Adresse
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                clients?.map((client, index) => {
                                    return(
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-3 py-1">
                                                <span>{ index += 1 }</span>
                                            </td>
                                            <th scope="row">
                                                <Link to="/admin/profile" className="px-3 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    { client.typeabon?.name} { client.society}
                                                </Link>
                                            </th>
                                            <td className="px-3 py-1">
                                                { client.name}
                                            </td>
                                            <td className="px-3 py-1">
                                                { client.email}
                                            </td>
                                            <td className="px-3 py-1">
                                                { client.tel}
                                            </td>
                                            <td className="px-3 py-1">
                                                { client.adress}
                                            </td>
                                            <td className="flex justify-center items-center px-3 py-1">
                                                {/* <Link to="/admin/profile" className="rounded font-medium  bg-green-500 hover:bg-green-700 durarion-300 hover:underline p-[5px]"><EyeIcon className="w-2 h-2 text-slate-100"/></Link> */}
                                                <Link className={ handleCheck("supprimer membre") ? "rounded font-medium text-red-50 bg-red-600 dark:text-red-500 hover:underline ms-1 p-[5px]" : "d-none"} ><TrashIcon className="w-2 h-2 text-slate-100"/></Link>
                                                <Link className={ handleCheck("modifier membre") ? "rounded font-medium  bg-blue-600 hover:bg-blue-800 durarion-300 hover:underline ms-1 p-[5px]" : "d-none"} onClick={() => editClient(client)}><PencilSquareIcon className="w-2 h-2 rounded text-slate-100"/></Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div> 
            </section>
        </>
    )
}

export default ClientList