
const data = {
    typeAbonements:[
        {
            id: 1,
            name: 'Standard',
        },
        {
            id: 2,
            name: 'Premium',
        },
        {
            id: 2,
            name: 'VIP',
        }
    ]
}

export default data;