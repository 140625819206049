import { XCircleIcon } from '@heroicons/react/24/solid'
import React, { useEffect } from 'react'
import ListNav from './ListNav';

const Navbar = ({showNavbar,setShowSideBare}) => {
  useEffect(() => {
    
  }, [showNavbar])
  const HideSideBarFun = () => {
    setShowSideBare(sideBare => !sideBare)
  }
  return (
    <>
      <div className="w-screen md:hidden block absolute top-[0] left-[0] h-screen z-[2]">
        <div onClick={HideSideBarFun} className="w-full h-full bg-sky-900 bg-opacity-50 "></div>
        <div className="w-screen absolute top-[0] right-[0] h-screen sm:w-[70%] z-[999] bg-sky-900">
          <div className="p-[10px]">
            <button onClick={HideSideBarFun} className="float-right">
              <XCircleIcon className="w-[40px] h-[40px] text-slate-100"/>
            </button>
            <div className="pt-[50px]">
              <ListNav/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar