import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '../../components/Admin/Partials'
import { Modal } from 'flowbite-react';
import { ClientForm, ClientList, EquipeForm, EquipeList } from '../../components/Admin/Elements';
import { EyeIcon, PencilSquareIcon, SwatchIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline';

const Clients = () => {
    const [openEquipeFormModal, setOpenEquipeFormModal] = useState(false);
    const [openEquipeListModal, setOpenEquipeListModal] = useState(false);
    const [openDeleteModal1, setOpenDeleteModal1] = useState(false);
    const [Typeabons, setTypeabons] = useState([]);
    const [Clients, setClients] = useState([]);
    const [Client, setClient] = useState(null);
    const [openCLientListModal, setOpenCLientListModal] = useState(false);
    
    const editClient = async (client=null) => {
        setOpenCLientListModal(!openCLientListModal)
        if(client == 1){
            client = null
            await fetchClients()
        }
        setClient(client)
    }
    async function fetchClients() {
        let response = await fetch('https://apimrclean.onrender.com/users/profileId/f3843869-9376-4fb6-a5ae-4f695b232d15' )
        response = await response.json()
        setClients(response)
        
        response = await fetch('https://apimrclean.onrender.com/typeabons'  )
        response = await response.json()
        setTypeabons(response)
    }
    useEffect(() => {
        fetchClients()
    }, [])

    const handleCheck = (val) => {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }
    
    return (
        <>
            <Breadcrumb currentPageTitle="Clients"/>
            <section className="bg-white shadow-xl w-full rounded-md p-2">
                <div className="flex justify-between flex-wrap">
                <h4 className="font-semibold text-1xl">Clients</h4>
                <div className="flex flex-wrap">
                    <button onClick={() => editClient()} className={ handleCheck("ajouter membre") ? "mr-2 mb-2 rounded p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]" : "d-none"}>Ajouter client</button>
                </div>
                </div>
                <div className="px-[20px]">
                    <ClientList clients={Clients} editClient={ editClient} />
                </div>
            </section>
            <Modal show={openCLientListModal} size="2xl" dismissible={true} onClose={() => editClient()} popup={true}>
                <ClientForm Client={ Client } typeabons={ Typeabons } editClient={ editClient } />
            </Modal>
        </>
    )
}

export default Clients;