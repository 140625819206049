import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '../../components/Admin/Partials'
import { Modal, TabItem } from 'flowbite-react';
import { EquipeForm, EquipeList } from '../../components/Admin/Elements';
import { PencilSquareIcon, SwatchIcon, UsersIcon } from '@heroicons/react/24/outline';
import EquipeUserForm from '../../components/Admin/Elements/EquipeUserForm';

const Equipe = () => {
  const [openEquipeFormModal, setOpenEquipeFormModal] = useState(false);
  const [openEquipeUserFormModal, setOpenEquipeUserFormModal] = useState(false);
  const [openEquipeListModal, setOpenEquipeListModal] = useState(false);
  const [Teams, setTeams] = useState([]);
  const [Team, setTeam] = useState(null);
  const [TeamUser, setTeamUser] = useState(null);
  const [TeamUsers, setTeamUsers] = useState([]);
  const [Users, setUsers] = useState([]);

  const ModalTeam = async (team = null) =>{
    setOpenEquipeFormModal(!openEquipeFormModal)
    if(team == 1){
      team = null
      await fetchTeams()
    }
    setTeam(team)
  }

  const ModalTeamUser = async (team = null) =>{
    setOpenEquipeUserFormModal(!openEquipeUserFormModal)
    if(team == 1){
      team = null
      await fetchTeams()
    }
    setTeam(team)
  }


  const ModalTeamList = async (team = null) =>{
    setOpenEquipeListModal(!openEquipeListModal)
    setTeamUsers([])
    if(team){
      let response = await fetch('https://apimrclean.onrender.com/teamusers/teamId/' + team?.id )
      response = await response.json()
      setTeamUsers(response)
    }
  }

  async function fetchTeams() {
      let response = await fetch('https://apimrclean.onrender.com/teams' )
      response = await response.json()
      setTeams(response)
  }

  async function fetchUsers() {
    let response = await fetch('https://apimrclean.onrender.com/users' )
    response = await response.json()
    setUsers(response.filter(val => val.profile?.id != "f3843869-9376-4fb6-a5ae-4f695b232d15"))
  }

  useEffect(() => {
    fetchTeams()
    fetchUsers()
  }, [])

  return (
    <>
      <Breadcrumb currentPageTitle="Equipes"/>
      <section className="bg-white shadow-xl w-full rounded-md p-2">
        <div className="flex justify-between flex-wrap">
          <h4 className="font-semibold text-1xl">Equipes</h4>
          <div className="flex flex-wrap">
            <button onClick={() => ModalTeam()} className="mr-2 mb-2 rounded p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]">Ajouter une équipe</button>
          </div>
        </div>
        <div className="flex flex-wrap justify-center">
          {
            Teams.map((team) => {
              return(
                <div className="w-full lg:w-[31%] lg:max-w-[370px] my-[0] mx-auto animate rounded-[0.325rem] pointer whiteBg text-left py-[20px] px-[30px] mt-[20px] shadow">
                    <div>
                        <div className="flex">
                          <UsersIcon className="h-5 w-5" />
                          <h4 className="ml-1 text-1xl font-semibold">{ team.name }</h4>
                        </div>
                    </div>
                    <div>
                        <div className="flexNullCenter" style={{ margin: "15px 0" }}>
                            <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                                <div style={{ minWidth: "20px" }}>
                                  <p className="font13">  { team.description }.</p>
                                  <p className="font11"> Ajouter par <strong>{ team.created?.name }</strong></p>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap justify-center ">
                            <button onClick={() => ModalTeamList(team)} title='Membres' className="text-sm rounded px-[7px] py-[3px] text-slate-100 duration-300 bg-[#7620ff] hover:bg-[#7620cf] flex justify-between items-center">
                              { team.teamusers?.length } # <UsersIcon className="ml-1 w-3 h-3 text-slate-100"/>
                            </button>

                            <button title='Affectation' type='button' onClick={ () => ModalTeamUser(team)} className="ml-1 text-sm rounded px-[7px] py-[3px] text-slate-100 duration-300 bg-gray-600 hover:bg-gray-800 flex justify-between items-center">
                              <SwatchIcon className="ml-1 w-3 h-3 text-slate-100"/>
                            </button>
                            <button  onClick={() => ModalTeam(team)} title='Editer equipe'  className="ml-1 rounded px-[7px] mt-1 py-[3px] text-slate-100 bg-green-500 duration-300 hover:bg-green-700 flex justify-between items-center "><PencilSquareIcon className="w-3 h-3 text-slate-100"/></button>
                        </div>
                    </div>
                </div>
              )
            })
          }

          
        </div>
      </section>
      <Modal show={openEquipeFormModal} size="md" dismissible={true} onClose={() => ModalTeam()} popup={true}>
        <EquipeForm Team={Team} ModalTeam={ ModalTeam} />
      </Modal>

      <Modal show={openEquipeUserFormModal} size="md" dismissible={true} onClose={() => ModalTeamUser()} popup={true}>
        <EquipeUserForm TeamUser={ TeamUser} Users={ Users.filter(val => val.profile?.id == 'f3843869-9376-4fb6-a5ae-4f695b232d13' || val.profile?.id == 'f3843869-9376-4fb6-a5ae-4f695b232d14') } Team={Team} ModalTeamUser={ ModalTeamUser } />
      </Modal>

      <Modal show={openEquipeListModal} size="5xl" dismissible={true} onClose={() => ModalTeamList()} popup={true}>
        <EquipeList TeamUsers={ TeamUsers } ModalTeamList={ ModalTeamList } />
      </Modal>
    </>
  )
}

export default Equipe