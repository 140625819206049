
import React, { useState } from "react";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const DeleteComponent = ({closeModal}) =>  {

  return (
    <>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-3 text-lg font-normal text-gray-500 dark:text-gray-400">
              Ete-vous sur de vouloir supprimer?
            </h3>
            <div className="flex justify-center gap-4">
                <Button color="failure" onClick="">
                    {"Yes, I'm sure"}
                </Button>
                <Button color="gray" onClick="">
                    No, cancel
                </Button> 
            </div>
          </div>
        </Modal.Body>
    </>
  );
}

export default DeleteComponent;