import axios from 'axios'
import { Modal } from 'flowbite-react'
import React, { useState } from 'react'

const EquipeUserForm = ({ TeamUser = null, Users = [], Team = null,  ModalTeamUser}) => {

    const [Error, setError] = useState("")
    const [TeamId, setTeamId] = useState(TeamUser?.teamId || "")
    const [UserId, setUserId] = useState(TeamUser?.userId || "")

    const Save = () => {
        setError("")
        let data = {
            teamId : Team?.id,
            userId : UserId
        }
        let request = TeamUser?.id ? axios.put('teamusers/'+TeamUser?.id, data) : axios.post('teamusers', data);
        request.then(async(response) =>{
            ModalTeamUser(1)
        }).catch((error) => {
            setError(error.response?.data?.msg)
        })

    }

  return (
    <>
       {/* <Modal.Header  /> */}
        <Modal.Body>
            <div className="space-y-2">
                <h3 className="text-1xl font-medium">
                    { TeamUser ? "Affectation "  : "Affectation "  } { Team?.name }
                </h3>
                <div>
                    <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
                </div>
                
                <div>
                    <div className="mb-2 block">
                        <label for="UserId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Personnel</label>
                    </div>
                    <select id="UserId" value={UserId} onChange={(e) => setUserId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option selected>Choisir le personnel </option>
                        { Users?.map((user) => (<option value={ user.id }> { user?.name } # { user?.profile?.name }</option>))}
                    </select>
                </div>

                <div className="w-full">
                    <button type='button' onClick={() => Save()} className="rounded p-[10px] text-sm text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]">Enregistrer</button>
                </div>
            </div>
        </Modal.Body>  
    </>
  )
}

export default EquipeUserForm