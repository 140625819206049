import axios from 'axios'
import { Modal } from 'flowbite-react'
import React, { useState } from 'react'

const StaffForm = ({ Staff, profiles = [], editStaff  }) => {
    const [Name, setName] = useState( Staff?.name || "")
    const [ProfileId, setProfileId] = useState( Staff?.profile?.id || "")
    const [Tel, setTel] = useState( Staff?.tel || "")
    const [Email, setEmail] = useState( Staff?.email || "")
    const [Adress, setAdress] = useState( Staff?.adress || "")
    const [Error, setError] = useState( "")

    const Save = () => {
        setError("")
        let data = {
            name : Name,
            profileId : ProfileId,
            tel : Tel,
            email : Email,
            adress : Adress,
        }
        let request = Staff?.id ? axios.put('users/'+ Staff?.id, data) : axios.post('users', data);
        request.then(async(response) =>{
            editStaff(1)
        }).catch((error) => {
            setError(error.response?.data?.msg)
        })
        
    }

    return (
        <>
            <Modal.Header className=" pb-[0] px-4 pt-4">
                <h3 className="text-1xl mb-3 font-medium">
                    { Staff ? "Modifier " + Staff?.profile?.name  : "Ajouter personnel" }
                </h3>
            </Modal.Header>
            <Modal.Body className="py-1">
                <div className="space-y-2">
                     
                    <div>
                        <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
                    </div>
                    
                    <form>

                        <div>
                            <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom complet</label>
                            <input type="text" id="name"  onChange={(e) => setName(e.target.value)} name="name"  value={Name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                        </div>
                        
                        <div className="grid gap-2 mb-6 md:grid-cols-2">
                        <div>
                            <div className="mb-2 block">
                                <label for="ProfileId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Profil</label>
                            </div>
                            <select id="ProfileId" value={ProfileId} onChange={(e) => setProfileId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choisir le profil </option>
                                { profiles?.map((profile) => (<option value={ profile.id }> { profile?.name }</option>))}
                            </select>
                        </div>
                        
                            <div>
                                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input type="email" id="email"  onChange={(e) => setEmail(e.target.value)} name="email"  value={ Email } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>  
                            <div>
                                <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Num. Tél</label>
                                <input type="tel" id="phone"  onChange={(e) => setTel(e.target.value)} name="tel"  value={ Tel } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>
                            <div>
                                <label for="adresse" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adresse</label>
                                <input type="text" id="adresse"  onChange={(e) => setAdress(e.target.value)} name="adress"  value={ Adress } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>
                        </div>
                        
                        <button type="button" onClick={() => Save() } className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{ Staff ? "Modifier" : "Enregistrer" }</button>
                    </form>

                </div>
            </Modal.Body> 
        </>
    )
}

export default StaffForm