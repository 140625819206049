import React from "react";
import styled from "styled-components";

export default function FaqBox({ title, text, textBr }) {
  return (
    <WrapperBtn className="animate shadow mt-[20px] rounded-[0.325rem]   pointer" >
      <Wrapper className=" ">
        <h3 className="font20 extraBold">{title}</h3>
        <p className="font13" style={{ padding: "20px 0" }}>
          {text}  <br /> {textBr}
        </p>
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
`;
const WrapperBtn = styled.button`
  border-left-width: 4px ;
  border-color: #7620ff;
`;
