import { PencilSquareIcon, CheckIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import { Badge, Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const ProgramList = ({ Abonnement = null, showModalProgramList}) => {
    
    const [Programs, setPrograms] = useState([])

    async function fetchPrograms() {
        let response = await fetch('https://apimrclean.onrender.com/programs/abonnementId/' + Abonnement?.id)
        response = await response.json()
        setPrograms(response)
    }
    useEffect(() => {
        fetchPrograms()
    }, [])
    
    return (
        <>
            <Modal.Header className=" pb-[0] px-4 pt-4">
                <h3 className="text-1xl mb-3 font-medium">
                    Horaire  { Abonnement?.typeabonnement?.name }  { Abonnement?.user?.typeabon?.name } { Abonnement?.user?.name }
                </h3>
            </Modal.Header>
            <Modal.Body className="py-1">
                <div className="relative max-h-[60vh] z-[9999] overflow-x-auto overflow-y-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-1">
                                    <div className="flex items-center">
                                        No.
                                    </div>
                                </th>
                                
                                <th scope="col" className="px-3 py-1">
                                    Date d'enregistrement
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Equipe
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Description
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Initié par
                                </th>

                                <th scope="col" className="px-3 py-1">
                                    Confirmer
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Programs.map((program, index) => (
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td className="w-4 p-4">
                                            { index += 1}.
                                        </td>
                                        
                                        <td className="px-3 py-1">
                                            { new Date(program.date)?.toLocaleDateString("en-GB")}
                                        </td>
                                        <td className="px-3 py-1">
                                            { program?.team?.name}
                                        </td>
                                        <td className="px-3 py-1">
                                            { program?.description}
                                        </td>
                                        <td className="px-3 py-1">
                                            { program?.user?.name}
                                        </td>
                                            
                                        <td className="flex justify-center items-center px-3 py-1">
                                            { program.isconfirm == 0 ? <Link className="rounded font-medium  bg-blue-600 hover:bg-blue-800 durarion-300 hover:underline p-[5px]"><CheckIcon className="w-2 h-2 text-slate-100"/></Link> : <span><CheckCircleIcon className="w-2 h-2 text-slate-100"/></span> }
                                        </td>
                                    </tr>
                                ))
                            }
                            
                        </tbody>
                    </table>
                </div> 
            </Modal.Body>
        </>
    )
}

export default ProgramList