import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import loginBanner from "../../../assets/img/banners/HomeBanner2.jpg";
import { Link } from 'react-router-dom';
import { ArrowRightStartOnRectangleIcon, UserCircleIcon } from '@heroicons/react/24/outline';

const Dropdownprofile = () => {
 
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="rounded-full w-[35px] h-[35px] inline-flex items-center justify-center  bg-white shadow-[0_2px_10px] shadow-blackA4 outline-none hover:bg-violet3 border-2 border-solid border-sky-400 overflow-hidden"
          aria-label="Customise options"
        >
            <img src={loginBanner}className="w-full h-full object-cover" alt="profile" />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="min-w-[220px] bg-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
          sideOffset={5}
        >

          <DropdownMenu.Label className="pl-[25px] text-sm font-medium leading-[25px]">
            Options
          </DropdownMenu.Label>
            <Link to="/admin/profile" className="ml-auto pl-[25px] rounded hover:bg-sky-700 hover:text-slate-100 duration-500 py-1 flex items-center gap-[5px] leading-none text-[13px] ">
                <UserCircleIcon className="w-[20px] h-[20px]"/>
                <span className="text-[13px] ">Profile</span>
            </Link>
             <Link to="" className="ml-auto pl-[25px] rounded hover:bg-sky-700 hover:text-slate-100 duration-500 py-1 flex items-center gap-[5px] leading-none text-[13px] ">
                <ArrowRightStartOnRectangleIcon className="w-[20px] h-[20px]"/>
                <span className="text-[13px] ">Déconnexion</span>
            </Link>
          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default Dropdownprofile;