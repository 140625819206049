import { EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ReportList = ({ Abonnements = [], modalProgram }) => {
    let somme = 0, somme2 = 0, somme3 = 0
    return (
        <>
            <section>
                <div className="relative max-h-[60vh] overflow-x-auto overflow-y-auto ">
                    <table className="w-full text-sm text-left rtl:text-right ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-1">
                                    <div className="flex items-center">
                                        No.
                                    </div>
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Client
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Période
                                </th>
                                <th scope="col" className="px-3 py-1">
                                    Prix
                                </th>

                                <th scope="col" className="px-3 py-1">
                                    Date début
                                </th>

                                <th scope="col" className="px-3 py-1">
                                    Confirmé par
                                </th>

                                {/* <th scope="col" className="px-3 py-1">
                                    
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>

                            <tr className= { Abonnements?.filter(val => val.status =="Demande")?.length > 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" : "d-none"}>
                                <td className="px-3 py-1" colSpan={6}>
                                <span>{"=>"}. </span> <span className= { "px-1 py-[2px] rounded text-slate-800 font-semibold bg-red-300"}> Demandes abonnements  </span>
                                </td>
                            </tr>

                            {
                                Abonnements?.filter(val => val.status =="Demande").map((abonnement, index) => {
                                    somme += parseFloat(abonnement.net || 0)
                                    return(
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-3 py-1">
                                                <span>{ index += 1 }</span>
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.user?.typeabon?.name} { abonnement.user?.name}
                                            </td>
                                            <td className="px-3 py-1">
                                                <strong>{ abonnement.period}  mois</strong> <span className= { abonnement.status == "Demande" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-red-300" : abonnement?.status == "Abonnement" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-blue-300" : "px-1 py-[2px] rounded text-slate-800 font-semibold bg-green-300"}> { abonnement?.status}  </span>
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.net?.toString()} USD
                                            </td>
                                            <td className="px-3 py-1">
                                                { new Date(abonnement.datestart)?.toLocaleDateString("en-GB") }
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.confirm?.name}
                                            </td>
                                            {/* <td className="flex justify-center items-center px-3 py-1">
                                                <Link className={   "rounded font-medium  bg-blue-600 hover:bg-blue-800 durarion-300 hover:underline ms-1 p-[5px]"  } onClick={() => modalProgram(abonnement)}><PencilSquareIcon className="w-2 h-2 rounded text-slate-100"/></Link>
                                            </td> */}
                                        </tr>
                                    )
                                })
                            }
                            <tr className= { Abonnements?.filter(val => val.status =="Demande")?.length > 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" : "d-none"}>
                                <th className="px-3 py-1" colSpan={3}>Total </th>
                                <th className='px-3 py-1'>{ somme?.toLocaleString() } USD</th>
                            </tr>

                            <tr className={ Abonnements?.filter(val => val.status =="Abonnement")?.length > 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" : "d-none"}>
                                <td className="px-3 py-1" colSpan={6}>
                                <span>{"=>"}. </span> <span className= { "px-1 py-[2px] rounded text-slate-800 font-semibold bg-blue-300"}> Abonnements en cours  </span>
                                </td>
                            </tr>
                            {
                                Abonnements?.filter(val => val.status =="Abonnement").map((abonnement, index) => {
                                    somme2 += parseFloat(abonnement.net || 0)
                                    return(
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-3 py-1">
                                                <span>{ index += 1 }</span>
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.user?.typeabon?.name} { abonnement.user?.name}
                                            </td>
                                            <td className="px-3 py-1">
                                                <strong>{ abonnement.period}  mois</strong> <span className= { abonnement.status == "Demande" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-red-300" : abonnement?.status == "Abonnement" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-blue-300" : "px-1 py-[2px] rounded text-slate-800 font-semibold bg-green-300"}> { abonnement?.status}  </span>
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.net?.toString()} USD
                                            </td>
                                            <td className="px-3 py-1">
                                                { new Date(abonnement.datestart)?.toLocaleDateString("en-GB") }
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.confirm?.name}
                                            </td>
                                            {/* <td className="flex justify-center items-center px-3 py-1">
                                                <Link className={   "rounded font-medium  bg-blue-600 hover:bg-blue-800 durarion-300 hover:underline ms-1 p-[5px]"  } onClick={() => modalProgram(abonnement)}><PencilSquareIcon className="w-2 h-2 rounded text-slate-100"/></Link>
                                            </td> */}
                                        </tr>
                                    )
                                })
                            }

                            <tr className= { Abonnements?.filter(val => val.status =="Abonnement")?.length > 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" : "d-none"}>
                                <th className="px-3 py-1" colSpan={3}>Total </th>
                                <th className='px-3 py-1'>{ somme2?.toLocaleString() } USD</th>
                            </tr>

                            <tr className={ Abonnements?.filter(val => val.status =="Cloturé")?.length > 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" : "d-none"}>
                                <td className="px-3 py-1" colSpan={6}>
                                <span>{"=>"}. </span> <span className= { "px-1 py-[2px] rounded text-slate-800 font-semibold bg-green-300"}> Abonnements cloturés  </span>
                                </td>
                            </tr>


                            {
                                Abonnements?.filter(val => val.status =="Cloturé")?.map((abonnement, index) => {
                                    somme3 += parseFloat(abonnement.net || 0)
                                    return(
                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-3 py-1">
                                                <span>{ index += 1 }</span>
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.user?.typeabon?.name} { abonnement.user?.name}
                                            </td>
                                            <td className="px-3 py-1">
                                                <strong>{ abonnement.period}  mois</strong> <span className= { abonnement.status == "Demande" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-red-300" : abonnement?.status == "Abonnement" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-blue-300" : "px-1 py-[2px] rounded text-slate-800 font-semibold bg-green-300"}> { abonnement?.status}  </span>
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.net?.toString()} USD
                                            </td>
                                            <td className="px-3 py-1">
                                                { new Date(abonnement.datestart)?.toLocaleDateString("en-GB") }
                                            </td>
                                            <td className="px-3 py-1">
                                                { abonnement.confirm?.name}
                                            </td>
                                            {/* <td className="flex justify-center items-center px-3 py-1">
                                                <Link className={   "rounded font-medium  bg-blue-600 hover:bg-blue-800 durarion-300 hover:underline ms-1 p-[5px]"  } onClick={() => modalProgram(abonnement)}><PencilSquareIcon className="w-2 h-2 rounded text-slate-100"/></Link>
                                            </td> */}
                                        </tr>
                                    )
                                })
                            }
                            <tr className= { Abonnements?.filter(val => val.status =="Cloturé")?.length > 0 ? "bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" : "d-none"}>
                                <th className="px-3 py-1" colSpan={3}>Total </th>
                                <th className='px-3 py-1'>{ somme3?.toLocaleString() } USD</th>
                            </tr>

                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <th className="px-3 py-1" colSpan={3}>Total global</th>
                                <th className='px-3 py-1'>{ (somme + somme2 + somme3)?.toLocaleString() } USD</th>
                            </tr>
                        </tbody>
                    </table>
                </div> 
            </section>
        </>
    )
}

export default ReportList