import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import Home from "../pages/Home.jsx";
import Pageerror from "../pages/Errors/Pageerror";
import Base from "../layouts/Base";
import Dashboard from "../pages/admin/Dashboard.jsx";
import BaseAdmin from "../layouts/BaseAdmin.jsx";
import { Abonement, Clients, Equipe, Profile, Programe, TypeAbonement, Login, Register, Rapport } from "../pages/admin";
import Demande from "../pages/admin/Demande.jsx";
import Cloturer from "../pages/admin/Cloturer.jsx";
import Staff from "../pages/admin/Staff.jsx";

let permissions = JSON.parse(localStorage.getItem("permissions"))
? JSON.parse(localStorage.getItem("permissions"))
: [];

let user = JSON.parse(localStorage.getItem("user"))
? JSON.parse(localStorage.getItem("user"))
: false;

function handleCheck(val) {
    return  (permissions ?? []).some(item => val === item.name);
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* Web site router */}

            <Route
                path="/"
                element={<Base/>}
                errorElement={<Pageerror/>}
            >
                <Route index element={<Home/>}/>
            </Route>

            {/* Admin router */}
            
            {
                (user?.id && handleCheck('admin')) && (
                <Route 
                    path="/admin"
                    element={<BaseAdmin/>}
                    errorElement={<Pageerror/>}
                >
                    <Route index element={<Dashboard/>}/>
                    <Route path="/admin/request" element={<Demande />}/>
                    <Route path="/admin/abonement" element={<Abonement/>}/>
                    <Route path="/admin/finish" element={<Cloturer/>}/>
                    <Route path="/admin/typeabonement" element={<TypeAbonement/>}/>
                    <Route path="/admin/programe" element={<Programe/>}/>
                    { handleCheck("afficher membre") == true && ( <Route path="/admin/clients" element={<Clients />}/> )}
                    { handleCheck("afficher utilisateur") == true && ( <Route path="/admin/users" element={<Staff />}/> )}
                    <Route path="/admin/profile" element={<Profile/>}/>
                    { handleCheck("afficher équipe") == true && ( <Route path="/admin/equipe" element={<Equipe/>}/> )}
                    { handleCheck("admin") == true && ( <Route path="/admin/rapport" element={<Rapport/>}/> )}
                </Route>
            )}
            
            {/* <Route
                path="/admin/login"
                element={<AuthLayout/>}
                errorElement={<Pageerror/>}
            > */}
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
            {/* </Route> */}
        </>
    )
)
export default router;