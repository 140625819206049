import axios from 'axios'
import { Modal } from 'flowbite-react'
import React, { useState } from 'react'

const EquipeForm = ({ Team = null, ModalTeam}) => {

    const [Error, setError] = useState("")
    const [Name, setName] = useState(Team?.name || "")
    const [Description, setDescription] = useState(Team?.description || "")

    const Save = () => {
        setError("")
        let data = {
            name : Name,
            description : Description
        }
        let request = Team?.id ? axios.put('teams/'+Team?.id, data) : axios.post('teams', data);
        request.then(async(response) =>{
            ModalTeam(1)
        }).catch((error) => {
            setError(error.response?.data?.msg)
        })

    }

  return (
    <>
       {/* <Modal.Header  /> */}
        <Modal.Body>
            <div className="space-y-2">
                <h3 className="text-1xl font-medium">
                    { Team ? "Editer équipe" : "Ajouter une équipe" }
                </h3>
                <div>
                    <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
                </div>

                <div>
                    <label htmlFor="name"  value={Name}  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom de l'équipe</label>
                    <input type="text" id="name"  onChange={(e) => setName(e.target.value)} name="name"  value={Name}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>
                <div>
                    <label htmlFor="description"   className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">description de l'équipe</label>
                    <input type="text" id="description"  onChange={(e) => setDescription(e.target.value)}  name="description"  value={ Description}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>

                <div className="w-full">
                    <button type='button' onClick={() => Save()} className="rounded p-[10px] text-sm text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]">Enregistrer</button>
                </div>
            </div>
        </Modal.Body>  
    </>
  )
}

export default EquipeForm