import React, { useState } from 'react'
import loginBanner from "../../assets/img/banners/HomeBannerd.jpg";
import LogoIcon from "../../assets/svg/Logo";
import { Link } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const [Username, setUsername] = useState("")
  const [Password, setPassword] = useState("")
  const [Error, setError] = useState("")

  const Save = () => {
    setError("")
    let data = {
      username : Username,
      password : Password
    }
    axios.post("https://apimrclean.onrender.com/login", data)
    .then(login => {
      localStorage.setItem("user", JSON.stringify(login.data.data));
      localStorage.setItem("permissions", JSON.stringify(login.data.permissions ? login.data.permissions : []) );
      window.location.href="/admin"
    })
    .catch(error =>{
      setError(error.response?.data?.msg)
    })
  }
  return (
    <>
      <div className="flex items-center justify-between">
        <div className="container">
          <div className="text-center mb-[50px]  mt-[50px] ">
            <div className="flex justify-center">
              <p className="pointer text-center flexNullCenter" to="/home">
                <LogoIcon />
                <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                  MrClean
                </h1>
              </p>
            </div>
            <h3 className="text-2xl font-medium">
              Connectez vous à votre compte
            </h3>
            <p className="font-extralight"><small>Connectez-vous pour avoir plus d'experience <Link to="/" className="ml-2 text-[#7620ff]">Visitez notre site</Link></small></p>
          </div>
          <div className="shadow  py-[20px] rounded-lg">
             <div className="space-y-2">

                <div>
                  <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
                </div>

                <div>
                  <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email ou numéro de téléphone</label>
                  <input type="text" id="email" onChange={(e) => setUsername(e.target.value)} value={ Username } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>

                <div>
                  <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mot de passe</label>
                  <input type="password" id="password"  onChange={(e) => setPassword(e.target.value)} value={ Password } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>

                <div className="w-full">
                    <button type="button" onClick={() => Save()} className="rounded text-sm font-bold p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]">Connexion</button>
                </div>
                {/* <p className="text-right">
                  <small>Vous n'avez pas un compte?<Link to="/register" className="ml-2 text-[#7620ff]">Créer un compte</Link></small>
                </p> */}
            </div>
          </div>
        </div>
        <div className="w-full hidden md:block h-screen">
          <div className="overflow-hidden w-full h-full">
            <img src={loginBanner} alt="auth banner" className="h-full w-full object-cover" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Login