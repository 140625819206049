import axios from 'axios'
import { Modal } from 'flowbite-react'
import React, { useState } from 'react'

const ClientForm = ({ Client, typeabons = [], editClient  }) => {
    const [Name, setName] = useState( Client?.name || "")
    const [Society, setSociety] = useState( Client?.society || "")
    const [TypeabonId, setTypeabonId] = useState( Client?.typeabon?.id || "")
    const [Tel, setTel] = useState( Client?.tel || "")
    const [Email, setEmail] = useState( Client?.email || "")
    const [Adress, setAdress] = useState( Client?.adress || "")
    const [Error, setError] = useState( "")

    const Save = () => {
        setError("")
        let data = {
            name : Name,
            society : Society,
            typeabonId : TypeabonId,
            tel : Tel,
            email : Email,
            adress : Adress,
            profileId : "f3843869-9376-4fb6-a5ae-4f695b232d15"
        }
        let request = Client?.id ? axios.put('users/'+ Client?.id, data) : axios.post('users', data);
        request.then(async(response) =>{
            editClient(1)
        }).catch((error) => {
            setError(error.response?.data?.msg)
        })
        
    }

    return (
        <>
            <Modal.Header className=" pb-[0] px-4 pt-4">
                <h3 className="text-1xl mb-3 font-medium">
                    { Client ? "Modifier " + Client.society : "Ajouter un client" }
                </h3>
            </Modal.Header>
            <Modal.Body className="py-1">
                <div className="space-y-2">
                     
                    <div>
                        <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
                    </div>
                    
                    <form>
                        <div className="grid gap-2 mb-6 md:grid-cols-2">
                        <div>
                            <div className="mb-2 block">
                                <label for="typeabonId" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type d'abonnement</label>
                            </div>
                            <select id="typeabonId" value={TypeabonId} onChange={(e) => setTypeabonId(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Choisir un type d'abonné </option>
                                { typeabons?.map((typeabon) => (<option value={ typeabon.id }> { typeabon?.name }</option>))}
                            </select>
                        </div>
                        
                            <div>
                                <label for="society" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Libele client</label>
                                <input type="text" id="society"  onChange={(e) => setSociety(e.target.value)} name="society"  value={Society} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>
                            <div>
                                <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Personne de contact</label>
                                <input type="text" id="name"  onChange={(e) => setName(e.target.value)} name="name"  value={Name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>
                            <div>
                                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                                <input type="email" id="email"  onChange={(e) => setEmail(e.target.value)} name="email"  value={ Email } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>  
                            <div>
                                <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Num. Tél</label>
                                <input type="tel" id="phone"  onChange={(e) => setTel(e.target.value)} name="tel"  value={ Tel } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>
                            <div>
                                <label for="adresse" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adresse</label>
                                <input type="text" id="adresse"  onChange={(e) => setAdress(e.target.value)} name="adress"  value={ Adress } className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " required />
                            </div>
                        </div>
                        
                        <button type="button" onClick={() => Save() } className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-normal rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">{ Client ? "Modifier" : "Enregistrer" }</button>
                    </form>

                </div>
            </Modal.Body> 
        </>
    )
}

export default ClientForm