import React from 'react'
import { Outlet } from 'react-router-dom'
import TopNavbar from "../components/Nav/TopNavbar";

const Base = () => {
  return (
    <>
      <TopNavbar />
      <main className=" w-full p-[0px] m-[0px] ">
        <Outlet />
      </main>
    </>
  )
}

export default Base