

import React, { useEffect, useState } from "react";

import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import axios from "axios";

const ProgramForm = ({ Abonnement = null, showModalProgram}) => {

  const [Error, setError] = useState("");
  const [Description, setDescription] = useState(null);
  const [Datestart, setDatestart] = useState( null);
   
  const handleCheck = (val) => {
    var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
    return permissions.some(item => val === item.name)
}

  const Save = () =>{
    setError("")
    let data = {
      description : Description,
      date : new Date(Datestart),
      abonnementId : Abonnement?.id,
    }

    let request =   axios.post('programs', data);
    request.then(async(response) =>{
        showModalProgram(1)
    }).catch((error) => {
        setError(error.response?.data?.msg)
    })
  }

  return (
    <>
        <Modal.Header className=" pb-[0] px-4 pt-4">
            <h3 className="text-1xl mb-3 font-medium">
              Ajouter programmes { Abonnement?.user?.typeabon?.name} { Abonnement?.user?.society}
            </h3>
        </Modal.Header>

        <Modal.Body className="py-1">
          <div>
              <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
          </div>
   
            <div>
              <label for="datestart" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
              <input type="text" value={ Description } onChange={(e) => setDescription(e.target.value)} id="datestart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>

            <div>
              <label for="datestart" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date</label>
              <input type="Date" value={ Datestart } onChange={(e) => setDatestart(e.target.value)} id="datestart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>

          <div className="w-full">
              <button type="button" onClick={() => showModalProgram()} className="rounded text-sm p-[10px] text-[#7620ff] duration-300 me-4">Fermer</button>
              <button type="button" onClick={() => Save()} className="rounded text-sm p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]">Enregistrer</button>
          </div> 
      </Modal.Body>
    </>
  );
}

export default ProgramForm;