import React from "react";
import { Breadcrumb } from "../../components/Admin/Partials";
import ProfileContent  from "./Profile/ProfileContent";

const Profile = () => {
    return (
        <>
            <Breadcrumb currentPageTitle="Profile"/>
            <section className="bg-wite shadowl w-full rounded-md p-2">
                <div className="flex justify-between flex-wrap">
                    <h4 className="font-semibold text-1xl">Profile</h4>
                </div>
                <ProfileContent/>
            </section>
        </>
    )
}

export default Profile;