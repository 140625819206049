import React from "react";
import styled from "styled-components";
import AddAbout from "../../assets/img/add/addAbout.png";
import { Link } from "react-scroll";

export default function About() {
  return (
    <Wrapper id="about">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">A propos de <span className="text-[#7620ff]">MrClean</span></h1>
            <p className="font13">
              Nous nous engageaons a préserver l'environnemnt en facilittant la récupération et le recyclage de vos déchet. 
              <br/>
              Notre mission est de rendre la gestion des déchets simple, efficace et accessible à tous.
            </p>
          </HeaderInfo>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddAbout} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A propos de nos services!</h4>
              <h2 className="font40 extraBold">Engagement <span className="text-[#7620ff]">écologique</span></h2>
              <p className="font12">
                Notre équipe d'experts en nottoyage urbain est équipée pour faire face à tous les défies, assurant un service rapide, efficace et respectueux de l'environnement. 
                Cela téMoignent notre engagement envers un avenir plus vert.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <Link activeClass="active" className=" pointer bg-[#7620ff] px-3 py-2 rounded  hover:bg-violet-700 hover:text-slate-100 text-slate-100 " to="contact" spy={true} smooth={true} offset={-80}>
                Contactez-nous
                </Link>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
