import React from 'react'
import Banner from "../components/Sections/Banner";
import Services from "../components/Sections/Services";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import About from '../components/Sections/About';
import Faq from '../components/Sections/Faq';

const Home = () => {
  return (
    <>
        <Banner />
        <Services />
        <About />
        <Faq />
        <Pricing />
        <Contact />
        <Footer />
    </>
  )
}

export default Home