import React, { useState } from 'react';
import LogoIcon from "../../../assets/svg/Logo";
import { Link } from 'react-router-dom'
import { QueueListIcon } from '@heroicons/react/24/outline';
import Navbar from './Navbar';
import Dropdownprofile from './Dropdownprofile';

const Header = () => {
    const [ShowSideBare, setShowSideBare] = useState(false);

    const ShowSideBarFun = () => {
        setShowSideBare(sideBare => !sideBare)
    }
    return (
        <>
            <div className="relative">
                <div className="w-full md:w-[calc(100vw-230px)] -z-10">
                    <div className="w-full bg-white p-[15px]">
                        <div className="flex justify-between">
                            <Link className="  animate pointer" to="home" >
                                <div className="md:hidden">
                                    <div className="flex items-center">
                                        <LogoIcon />
                                        <h1 className="font20 extraBold text-sky-900" style={{ marginLeft: "15px" }}>
                                            MrClean
                                        </h1>
                                    </div>
                                </div>
                            </Link>
                            <div className="flex gap-4 items-center justify-end">
                                <Dropdownprofile/>

                                <button onClick={ShowSideBarFun} className="block md:hidden">
                                    <QueueListIcon className="w-[40px] h-[40px] text-[40px]"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    ShowSideBare && <Navbar setShowSideBare={setShowSideBare} showNavbar={ShowSideBare}/>
                }
            </div>

        </>
    )
}

export default Header