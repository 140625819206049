import React, { useEffect, useState } from 'react'
import ReportList from '../../components/Admin/Elements/ReportList';
import { Breadcrumb } from '../../components/Admin/Partials';

const Rapport = () => {
  
  const [Typeabonnements, setTypeabonnements] = useState([]);
  const [Typeabonnement, setTypeabonnement] = useState(null);
  const [Abonnements, setAbonnements] = useState([]);

  async function fetchAbonnements(val=null) {
    setTypeabonnement(val)
      let response = await fetch('https://apimrclean.onrender.com/abonnements/typeabonnementId/' + val )
      response = await response.json()
      setAbonnements(response)
      
  }

  async function fetchTypeabonnement() {
    let response = await fetch('https://apimrclean.onrender.com/typeabonnements' )
    response = await response.json()
    fetchAbonnements(response[0]?.id)
    setTypeabonnements(response)
}

useEffect(() => {
    fetchTypeabonnement()
}, [])


const modalProgram = (val) => {
  alert(val)
}

  return (
      <>
          <Breadcrumb currentPageTitle="Rapport"/>
          <section className="bg-white shadow-xl w-full rounded-md p-2">
              <div className="flex justify-between flex-wrap">
                <div className='m-5'>
                    {
                        Typeabonnements.map((typeabonnement) => (
                            <span className={ Typeabonnement == typeabonnement?.id ? "text-blue-500 font20 extraBold ms-5 ps-5" :"font-semibold ms-5 ps-5"} type="button" onClick={() => fetchAbonnements(typeabonnement?.id)}>{ typeabonnement.name }</span>
                        ))
                    }
                </div>

              {/* <div className="flex flex-wrap d-none">
                  <button className={  "mr-2 mb-2 rounded p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[red]"}>28-05-2024 25-6-2024</button>
              </div> */}

              </div>
              <div className="px-[20px]">
                  <ReportList Abonnements={ Abonnements } modalProgram={ modalProgram} />
              </div>
          </section>
      </>
  )
}

export default Rapport