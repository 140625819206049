import React, { useEffect, useState } from "react";
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import CheckMark from "../../assets/svg/Checkmark";
import { Modal } from "flowbite-react";
import { AbonementForm, ProgramList, TypeAbonementList } from "../../components/Admin/Elements";
import DeleteComponent from "../../components/Admin/Partials/DeleteComponent";
import { EyeIcon, PencilSquareIcon, TrashIcon, CreditCardIcon } from "@heroicons/react/24/outline";
import { Breadcrumb } from "../../components/Admin/Partials";
import axios from "axios";

const Demande = () => {
    const [OpenModalAbonnement, setOpenModalAbonnement] = useState(false);
    const [OpenDeleteModal1, setOpenDeleteModal1] = useState(false);
    const [OpenDeleteModal2, setOpenDeleteModal2] = useState(false);
    const [OpenDeleteModal3, setOpenDeleteModal3] = useState(false);
    const [OpenAbonementListModal, setOpenAbonementListModal] = useState(false);
    const [OpenTypeAbonementModal, setOpenTypeAbonementModal] = useState(false);
    const [Abonnements, setAbonnements] = useState([])
    const [Abonnement, setAbonnement] = useState(null)

    async function fetchAbonnements() {
        let response = await fetch('https://apimrclean.onrender.com/abonnements/status/Demande')
        response = await response.json()
        let user = localStorage.getItem('user')
        user = user ? JSON.parse(user) : null
        if(user?.profileId == 'f3843869-9376-4fb6-a5ae-4f695b232d15')
            response = response.filter(val => val.userId == user?.id)

        console.log(response);
        setAbonnements(response)
    }

    useEffect(() => {
        fetchAbonnements()
    }, [])

    const showModalAbonnement = async(abonnement = null) => {
        if(abonnement == 1){
            abonnement = null
            await fetchAbonnements()
        }
        setAbonnement(abonnement)
        setOpenModalAbonnement(!OpenModalAbonnement)
    }

    const handleCheck = (val) => {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }

    const deleteAbonnement = (val) => {
        axios.delete("/abonnements/" + val)
        .then((val) => {
            fetchAbonnements()
        })
        .catch(error => {
            alert(error.response?.data?.msg)
        })
    }

    const confirmAbonnement = (val) => {
        let user = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")) : false;
        axios.put("/abonnements/" + val, { status : "Abonnement", confirmId : user?.id})
        .then((val) => {
            fetchAbonnements()
        })
        .catch(error => {
            alert(error.response?.data?.msg)
        })
    }

  return (
    <>
        <Breadcrumb currentPageTitle="Abonnements"/>
        <section className="bg-white shadow-xl w-full rounded-md p-2 mb-5 pb-5">
            <div className="flex justify-between flex-wrap">
                <h4 className="font-semibold mb-2 text-1xl">Demande d abonnements</h4>
                <div className="flex flex-wrap">
                    <button onClick={() => setOpenTypeAbonementModal(true)} className="mr-2 mb-2 rounded p-[10px] text-slate-100 bg-orange-500 duration-300 hover:bg-orange-800">Types d'abonnement</button>
                    {/* <button onClick={() => setOpenAbonementListModal(true)} className="mr-2 mb-2 rounded p-[10px] text-slate-100 bg-green-500 duration-300 hover:bg-green-800">Voir tous les abonement</button> */}
                    <button onClick={() => showModalAbonnement()} className={ handleCheck("ajouter abonnement") == true ? "rounded mb-2 p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]" : "d-none"}>Demande abonnement</button>
                </div>
            </div>

            <div className="flex flex-wrap mb-4 pb-4">
                {
                    Abonnements.map((abonnement) =>(
                            <div className="w-full lg:w-[31%] lg:max-w-[370px] my-[0] mx-auto animate border-solid border-l-[4px] border-l-[blue] rounded-[0.325rem] pointer whiteBg text-left py-[20px] px-[30px] mt-[20px] shadow">
                                <div className="flex items-center justify-start">
                                    <CreditCardIcon className="w-5 h-5" /> &nbsp;
                                    <p className="text-2xl font-bold">{ abonnement.user?.society}</p>
                                </div>
                                <div style={{ margin: "30px 0" }}>
                                    <h4 className="text-1xl font-semibold">{ abonnement.typeabonnement?.name}  / { abonnement.period } mois</h4>
                                    <p className="font13">Responsable : <strong>{ abonnement.user?.name }</strong> </p>
                                    <p className="font13">Ajouter par : <strong><span className="text-[#7620ff]"> { abonnement.created?.name }</span></strong></p>
                                    <p className="font13"><strong>Montant net : <span className="text-[#7620ff]"> { abonnement.net?.toLocaleString() }$</span></strong></p>
                                    <p className="font11">Du <strong><small className="text-[#7620ff]">{ new Date(abonnement.datestart).toLocaleDateString("en-GB") }</small></strong> au <strong><small className="text-red-600">{ new Date(abonnement.dateend).toLocaleDateString("en-GB") }</small></strong></p>
                                </div>
                                <div>
                                    <div className="flexNullCenter" style={{ margin: "15px 0" }}>
                                        <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                                            <div style={{ minWidth: "20px" }}>
                                            <CheckMark />
                                            </div>
                                        </div>
                                        <p className="text-xl font-medium">{ abonnement.typeabonnement?.period}  </p>
                                    </div>
                                    <div className="flex">
                                        <button onClick={() => showModalAbonnement(abonnement)} type="button" className={ handleCheck("modifier abonnement") == true ? "ml-2 rounded px-[7px] py-[3px] text-slate-100 bg-green-500 duration-300 hover:bg-green-700 flex justify-between items-center" : "d-none"}>Modifier <PencilSquareIcon className="ml-1 w-3 h-3 text-slate-100"/></button>
                                        <button onClick={() => confirmAbonnement(abonnement.id)} type="button" className={ handleCheck("valider abonnement") == true ? "rounded px-[7px] py-[3px] text-slate-100 duration-300 bg-[#7620ff] hover:bg-[#7620cf] flex justify-between items-center ml-2" : "d-none"}>Confirmer <PencilSquareIcon className="ml-1 w-3 h-3 text-slate-100"/></button>
                                        <button onClick={() => deleteAbonnement(abonnement.id)} type="button" className={ handleCheck("supprimer abonnement") == true ? "rounded px-[7px] py-[3px] text-slate-100 bg-red-600 duration-300 hover:bg-red-700 flex justify-between items-center ml-2" : "d-none"}>Supprimer <TrashIcon className="ml-1 w-3 h-3 text-slate-100"/></button>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }

            </div>
        </section>
        <Modal show={OpenModalAbonnement} size="lg" dismissible={true}  onClose={() => showModalAbonnement()}  popup={true}>
            <AbonementForm Abonnement={ Abonnement}  showModalAbonnement={ showModalAbonnement} />
        </Modal>

        <Modal show={OpenDeleteModal1} size="md" dismissible={true} onClose={() => setOpenDeleteModal1(false)} popup={true}>
            <DeleteComponent closeModal=""  />
        </Modal>

        <Modal show={OpenDeleteModal2} size="md" dismissible={true} onClose={() => setOpenDeleteModal2(false)} popup={true}>
            <DeleteComponent/>
        </Modal>

        <Modal show={OpenDeleteModal3} size="md" dismissible={true} onClose={() => setOpenDeleteModal3(false)} popup={true}>
            <DeleteComponent/>
        </Modal>

        <Modal show={OpenAbonementListModal} size="5xl" dismissible={true} onClose={() => setOpenAbonementListModal(false)} popup={true}>
            <ProgramList/>
        </Modal>

        <Modal show={OpenTypeAbonementModal} size="xl" dismissible={true} onClose={() => setOpenTypeAbonementModal(false)} popup={true}>
            <TypeAbonementList/>
        </Modal>
    </>
  )
}

export default Demande