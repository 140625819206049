

import React, { useEffect, useState } from "react";

import { Button, Checkbox, Label, Modal, TextInput } from "flowbite-react";
import axios from "axios";

const AbonnementForm = ({ Abonnement = null, showModalAbonnement}) => {

  const [Error, setError] = useState("");
  const [Abonnes, setAbonnes] = useState( []);
  const [TypeAbonnements, setTypeAbonnements] = useState( []);
  const [AbonneId, setAbonneId] = useState( Abonnement ? Abonnement.userId : null);
  const [TypeAbonnementId, setTypeAbonnementId] = useState( Abonnement ? Abonnement.typeabonnementId : null);
  const [Datestart, setDatestart] = useState( Abonnement ? new Date(Abonnement.datestart) : null);
  const [Period, setPeriod] = useState( Abonnement ? Abonnement.period : 1);

  const handleCheck = (val) => {
    var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
    return permissions.some(item => val === item.name)
}

  async function fetchAbonnes() {

    if(handleCheck("personnel") == true){
      let response = await fetch('https://apimrclean.onrender.com/users/profileId/f3843869-9376-4fb6-a5ae-4f695b232d15')
      response = await response.json()
      setAbonnes(response)
    }
    else{
      let user = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user")) : false;
      setAbonneId(user?.id)
    }


    let response = await fetch('https://apimrclean.onrender.com/typeabonnements/')
    response = await response.json()
    setTypeAbonnements(response)
}

  useEffect(() => {
    fetchAbonnes()
  }, [])

  const Save = () =>{
    setError("")
    let date = new Date(Datestart)
    let data = {
      userId : AbonneId,
      typeabonnementId : TypeAbonnementId,
      datestart : new Date(Datestart),
      dateend : new Date(date.setMonth(date.getMonth() + parseInt(Period))),
      period : Period,
      net : parseFloat(TypeAbonnements.filter(val => val.id == TypeAbonnementId)[0]?.price) * parseFloat(Period)
    }

    console.log(data, "======");
    let request = Abonnement?.id ? axios.put('abonnements/'+ Abonnement?.id, data) : axios.post('abonnements', data);
    request.then(async(response) =>{
        showModalAbonnement(1)
    }).catch((error) => {
        setError(error.response?.data?.msg)
    })
  }

  return (
    <>
        <Modal.Header className=" pb-[0] px-4 pt-4">
            <h3 className="text-1xl mb-3 font-medium">
              { Abonnement ? "Modifer abonnement" : "Demander un abonement"}
            </h3>
        </Modal.Header>

        <Modal.Body className="py-1">
          <div>
              <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
          </div>

          <div className={ Abonnes.length > 0 ? "mb-2 block" : "d-none"}>
            <div className="mb-2 block">
              <label for="abonementType" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Abonné</label>
            </div>
            <select id="abonementType" value={ AbonneId} name="typeabonnementId" onChange={(e) => setAbonneId(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option selected>Selectionner abonemet</option>
              {
                Abonnes.map((abonne) => (
                  <option value={ abonne.id}>{ abonne.typeabon?.name } { abonne.society }</option>
                ))
              }
            </select>
          </div>

          <div>
            <div className="mb-2 block">
              <label for="abonementType" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type d'abonnement</label>
            </div>
            <select id="abonementType" value={ TypeAbonnementId } name="typeabonnementId" onChange={(e) => setTypeAbonnementId(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option selected>Type d'abonemet</option>
              {
                TypeAbonnements.map((typeabonnement) => (
                  <option value={ typeabonnement.id}>{ typeabonnement.name }</option>
                ))
              }
            </select>
          </div>

          <div>
            <div className="mb-2 block">
              <label for="abonementType" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Période</label>
            </div>
            <select id="abonementType" value={ Period } name="period" onChange={(e) => setPeriod(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option selected>Periode d'abonnement</option>
              <option value={1}>1 mois</option>
              <option value={2}>2 mois</option>
              <option value={3}>3 mois</option>
              <option value={6}>6 mois</option>
              <option value={9}>9 mois</option>
              <option value={12}>12 mois</option>
              <option value={24}>24 mois</option>
            </select>
          </div>

            <div>
              <label for="datestart" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date debut</label>
              <input type="Date" value={ Datestart } onChange={(e) => setDatestart(e.target.value)} id="datestart" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
            </div>

          <div className="w-full">
              <button type="button" onClick={() => showModalAbonnement()} className="rounded text-sm p-[10px] text-[#7620ff] duration-300 me-4">Fermer</button>
              <button type="button" onClick={() => Save()} className="rounded text-sm p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]">Enregistrer</button>
          </div> 
      </Modal.Body>
    </>
  );
}

export default AbonnementForm;