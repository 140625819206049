import React from 'react'
import LogoIcon from "../../../assets/svg/Logo";
import { Link } from 'react-router-dom'
import { CreditCardIcon,  ListBulletIcon,ArrowPathRoundedSquareIcon, ServerStackIcon, AcademicCapIcon, UserCircleIcon, UsersIcon, LockClosedIcon, QueueListIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

const ListNav = () => {

    let permissions = JSON.parse(localStorage.getItem("permissions"))
    ? JSON.parse(localStorage.getItem("permissions"))
    : [];

    const handleCheck = (val) => {
        return  (permissions ?? []).some(item => val === item.name);
    }

    const Logout = () => {
        axios.delete("logout").then(val => {
            window.localStorage.clear();
            window.location.href = "/"
        }).catch(err => {
            window.localStorage.clear();
            window.location.href = "/"
        })
    }
  return (
    <>
        <div className="relative p-2">
            <Link className="pointer" to="/admin">
                <h1 className="font20 flex items-center font-bold text-white px-[10px]">
                    <LogoIcon />
                    <span className="ml-2">MrClean</span>
                </h1>
            </Link> 
            <ul className="flex mt-3 flex-col">
                <span className="px-[10px] font-semibold text-slate-300 py-2 font12">PAGES</span>
                <li className="font14">
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin">
                        <CreditCardIcon className="w-[20px] me-1"/>
                        Tableau de bord
                    </Link>
                </li>
                <li className="font14">
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin/request">
                        <ArrowPathRoundedSquareIcon className="w-[20px] me-1"/>
                        Demande
                    </Link>
                </li>

                <li className="font14">
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin/abonement">
                        <AcademicCapIcon className="w-[20px] me-1"/>
                        Abonnement
                    </Link>
                </li>

                <li className="font14">
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin/finish">
                        <ListBulletIcon className="w-[20px] me-1"/>
                        Cloturé
                    </Link>
                </li>

                <li className={ handleCheck("afficher membre") == true ? "font14" : "d-none"}>
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin/clients">
                        <UsersIcon className="w-[20px] me-1"/>
                        Clients
                    </Link>
                </li>
                
                <li className={ handleCheck("afficher utilisateur") == true ? "font14" : "d-none"}>
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin/users">
                        <UserCircleIcon className="w-[20px] me-1"/>
                        Staff
                    </Link>
                </li>

                <li className={ handleCheck("afficher équipe") == true ? "font14" : "d-none"}>
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin/equipe">
                        <UserGroupIcon className="w-[20px] me-1"/>
                        Equipe
                    </Link>
                </li>
                
                <li className={ handleCheck("admin") == true ? "font14" : "d-none"}>
                    <Link className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="/admin/rapport">
                        <ServerStackIcon className="w-[20px] me-1"/>
                        Rapport
                    </Link>
                </li>

                <li className="font14">
                    <Link onClick={() => Logout()} className=" flex pointer rounded duration-300 hover:bg-indigo-100 hover:text-indigo-500 p-[10px] text-slate-300" to="#">
                        <LockClosedIcon className="w-[20px] me-1"/>
                        Déconnexion
                    </Link>
                </li>

            </ul>
        </div>
    </>
  )
}

export default ListNav