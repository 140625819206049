import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '../../components/Admin/Partials'
import { Modal } from 'flowbite-react';
import { StaffForm, StaffList } from '../../components/Admin/Elements';
import { EyeIcon, PencilSquareIcon, SwatchIcon, TrashIcon, UsersIcon } from '@heroicons/react/24/outline';

const Staff = () => {
    const [openEquipeFormModal, setOpenEquipeFormModal] = useState(false);
    const [openEquipeListModal, setOpenEquipeListModal] = useState(false);
    const [openDeleteModal1, setOpenDeleteModal1] = useState(false);
    const [Profiles, setProfiles] = useState([]);
    const [ProfileId, setProfileId] = useState([]);
    const [Staffs, setStaffs] = useState([]);
    const [Staff, setStaff] = useState(null);
    const [openStaffListModal, setOpenStaffListModal] = useState(false);
    
    const editStaff = async (staff=null) => {
        setOpenStaffListModal(!openStaffListModal)
        if(staff == 1){
            staff = null
            await fetchStaff()
        }
        setStaff(staff)
    }

    async function fetchStaff(val) {
        setProfileId(val)
        let response = await fetch('https://apimrclean.onrender.com/users/profileId/' + val )
        response = await response.json()
        setStaffs(response)
    }

    async function fetchProfiles() {
        let response = await fetch('https://apimrclean.onrender.com/profiles' )
        response = await response.json()
        fetchStaff(response[0]?.id)
        setProfiles(response)
    }

    useEffect(() => {
        fetchProfiles()
    }, [])

    const handleCheck = (val) => {
        var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
        return permissions.some(item => val === item.name)
    }
    
    return (
        <>
            <Breadcrumb currentPageTitle="Staff"/>
            <section className="bg-white shadow-xl w-full rounded-md p-2">
                <div className="flex justify-between flex-wrap">
                    <div className='m-5'>
                    {
                        Profiles.filter(val => val.id != 'f3843869-9376-4fb6-a5ae-4f695b232d15').map((profil) => (
                            <span className={ ProfileId == profil?.id ? "text-blue-500 font20 extraBold ms-5 ps-5" :"font-semibold ms-5 ps-5"} type="button" onClick={() => fetchStaff(profil?.id)}>{ profil.name }</span>
                        ))
                    }
                    </div>

                    <div className="flex flex-wrap">
                        <div>
                            <button onClick={() => editStaff()} className={ handleCheck("ajouter utilisateur") ? "mr-2 mb-2 rounded p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]" : "d-none"}>Ajouter utilisateur</button>
                        </div>
                    </div>
                </div>
                <div className="px-[20px]">
                    <StaffList staffs={ Staffs } editStaff={ editStaff} />
                </div>
            </section>
            <Modal show={openStaffListModal} size="2xl" dismissible={true} onClose={() => editStaff()} popup={true}>
                <StaffForm Staff={ Staff } profiles={ Profiles.filter(val => val.id != 'f3843869-9376-4fb6-a5ae-4f695b232d15') }  editStaff={ editStaff } />
            </Modal>
        </>
    )
}

export default Staff;