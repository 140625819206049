import React from 'react'
import loginBanner from "../../assets/img/banners/HomeBannerd.jpg";
import LogoIcon from "../../assets/svg/Logo";
import { Link } from 'react-router-dom';
const Pageerror = () => {
  return (
    <div className="flex items-center justify-between">
        <div className="container">
          <div className="text-center mb-[50px]  mt-[50px]">
            <div className="flex justify-center">
              <p className="pointer text-center flexNullCenter" to="/home">
                <LogoIcon />
                <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                  MrClean
                </h1><br />
              </p>
            </div>
            <h3 className="text-2xl font-medium"><br />
              Une erreur s'est produite lors du chargement de la page
            </h3>
            <p className="font-extralight"><small>Veillez réactualiser la page svp</small></p>
          </div>
          <div className="shadow px-[50px] py-[20px] rounded-lg">
             <div className="space-y-2">

                <p className="text-right">
                  <small>Ou visitez notre <Link to="/" className="text-[#7620ff]">site</Link></small>
                </p>
            </div>
          </div>
        </div>
        <div className="w-full hidden md:block h-screen">
          <div className="overflow-hidden w-full h-full">
            <img src={loginBanner} alt="auth banner" className="h-full w-full object-cover" />
          </div>
        </div>
      </div>
  )
}

export default Pageerror