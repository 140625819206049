import axios from 'axios';
import styled from "styled-components";
import React, { useEffect, useState } from 'react'
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
import CheckMark from "../../assets/svg/Checkmark";
import {  UserCircleIcon,   UserGroupIcon } from '@heroicons/react/24/outline';

const Dashboard = () => {

  const [Abonnements, setAbonnements] = useState([])
  const [Programs, setPrograms] = useState([])
  const [Users, setUsers] = useState([])
  const [Teams, setTeams] = useState([])

  const handleCheck = (val) => {
    var permissions = JSON.parse(localStorage.getItem("permissions")) ? JSON.parse(localStorage.getItem("permissions")) : [];
    return permissions.some(item => val === item.name)
    }

  const refreshToken = async () => {
    try{
      var token = await axios.get('token');
      if(token.status === 200)
      {
          var user = (token.data.data)
          localStorage.setItem("user", JSON.stringify(user));
      }
      else if(token.status === 401)
      {
        axios.delete('https://apimrclean.onrender.com/logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
        window.localStorage.clear();
      }
    }catch(error) {
        axios.delete('https://apimrclean.onrender.com/logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
        window.localStorage.clear();
    }
}

async function fetchAbonnements() {
    let user = localStorage.getItem('user')
    user = user ? JSON.parse(user) : null
    let url = user?.profileId == 'f3843869-9376-4fb6-a5ae-4f695b232d15' ? 
    'https://apimrclean.onrender.com/abonnements/userId/' + user?.id : 
    'https://apimrclean.onrender.com/abonnements/'
  let response = await fetch(url)
  response = await response.json()
  setAbonnements(response)
}

async function fetchPrograms() {
    let user = localStorage.getItem('user')
    user = user ? JSON.parse(user) : null
    let url = user?.profileId == 'f3843869-9376-4fb6-a5ae-4f695b232d15' ? 
    'https://apimrclean.onrender.com/programs/$abonnement.userId$/' + user?.id : 
    'https://apimrclean.onrender.com/programs/'
  let response = await fetch(url)
  response = await response.json()
  setPrograms(response)
}

async function fetchClients() {
    let response = await fetch('https://apimrclean.onrender.com/users/profileId/f3843869-9376-4fb6-a5ae-4f695b232d15' )
    response = await response.json()
    setUsers(response)
    response = await fetch('https://apimrclean.onrender.com/teams' )
    response = await response.json()
    setTeams(response)
}

  useEffect(() => {
    fetchAbonnements()
    fetchPrograms()
    fetchClients()
    refreshToken()
  }, [])

  return (
    <>
    <div className='row'>
        <div className='col-md-4'>
            <div className='m-1'>
                <div className="">
                    
                <Statistics className="whiteBg radius8 shadow">
                    <div className="flexSpaceCenter">
                    <RollerIcon />
                    </div>
                    <div style={{ margin: "5px 0" }}>
                        <h4 className="font20 extraBold"> Demandes </h4>
                        <p className="font13">Demandes des abonnements </p>
                    </div>
                    <div>
                         
                        <div className="flexNullCenter" style={{ margin: "5px 0" }} >
                            <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                             
                                <div style={{ minWidth: "5px" }}>
                                    { Abonnements.filter(val => val.status =='Demande')?.length > 0 ? <CheckMark /> : <span></span> }
                                </div>
                             
                            </div>

                            <p className="font20 extraBold"> { Abonnements.filter(val => val.status =='Demande')?.length} </p>
                        </div>
                         
                    </div>
                </Statistics>


                </div>
            </div>
        </div>

        <div className='col-md-4'>
            <div className='m-1'>
                <div className="">
                    
                    <Statistics className="whiteBg radius8 shadow">
                        <div className="flexSpaceCenter">
                        <MonitorIcon />
                        </div>
                        <div style={{ margin: "5px 0" }}>
                            <h4 className="font20 extraBold"> Abonnements </h4>
                            <p className="font13">Abonnements encours </p>
                        </div>
                        <div>
                            
                            <div className="flexNullCenter" style={{ margin: "5px 0" }} >
                                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                                
                                    <div style={{ minWidth: "5px" }}>
                                        { Abonnements.filter(val => val.status =='Abonnement')?.length > 0 ? <CheckMark /> : <span></span> }
                                    </div>
                                
                                </div>

                                <p className="font20 extraBold"> { Abonnements.filter(val => val.status =='Abonnement')?.length} </p>
                            </div>
                            
                        </div>
                    </Statistics>

                </div>
            </div>
        </div>

        <div className='col-md-4'>
            <div className=' '>
                <div className="  ">
                    
                    <Statistics className="whiteBg radius8 shadow">
                        <div className="flexSpaceCenter">
                        <BrowserIcon />
                        </div>
                        <div style={{ margin: "5px 0" }}>
                            <h4 className="font20 extraBold"> Cloturés </h4>
                            <p className="font13">Abonnement colturés </p>
                        </div>
                        <div>
                            
                            <div className="flexNullCenter" style={{ margin: "5px 0" }} >
                                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                                
                                    <div style={{ minWidth: "5px" }}>
                                        { Abonnements.filter(val => val.status =='Cloturé')?.length > 0 ? <CheckMark /> : <span></span> }
                                    </div>
                                
                                </div>

                                <p className="font20 extraBold"> { Abonnements.filter(val => val.status =='Cloturé')?.length} </p>
                            </div>
                            
                        </div>
                    </Statistics>

                </div>
            </div>
        </div>

        <div className={ handleCheck('personnel') == true ?'col-md-4' : 'd-none col-md-4'}>
            <div className='m-1'>
                <div>
                    <Statistics className="whiteBg radius8 shadow">
                        <div className="flexSpaceCenter">
                        <UserCircleIcon className="w-[60px] me-1"/> 
                        </div>
                        <div style={{ margin: "0px 0" }}>
                            <h4 className="font20 extraBold"> Clients </h4>
                            <p className="font13">Nombre des clients </p>
                        </div>
                        <div>
                                
                            <div className="flexNullCenter" style={{ margin: "5px 0" }} >
                                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                                    
                                    <div style={{ minWidth: "5px" }}>
                                    { Users.length > 0 ?  <CheckMark /> : <span></span> }
                                    </div>
                                    
                                </div>
                                <p className="font20 extraBold"> { Users.length} </p>
                            </div>
                                
                        </div>
                    </Statistics>
                </div>
            </div>
        </div>

        <div className={ handleCheck('personnel') == true ?'col-md-4' : 'd-none col-md-4'}>
            <div className='m-1'>
                <div>
                    <Statistics className="whiteBg radius8 shadow">
                        <div className="flexSpaceCenter">
                        <UserGroupIcon className="w-[60px] me-1"/> 
                        </div>
                        <div style={{ margin: "0px 0" }}>
                            <h4 className="font20 extraBold"> Equipes </h4>
                            <p className="font13">Nombre des équipes </p>
                        </div>
                        <div>
                            
                            <div className="flexNullCenter" style={{ margin: "5px 0" }} >
                                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                                    
                                    <div style={{ minWidth: "5px" }}>
                                    { Teams.length > 0 ?  <CheckMark /> : <span></span> }
                                    </div>
                                    
                                </div>
                                <p className="font20 extraBold"> { Teams.length} </p>
                            </div>
                                
                        </div>
                    </Statistics>
                </div>
            </div>
        </div>

    </div>

    <br />
    <br />
    <br />
    <br />
      <div className='text-1xl mb-3 font-medium mt-10 font30 extraBold'>Recents programmes</div>

      <div className=''>
      <div className="relative max-h-[60vh] overflow-x-auto overflow-y-auto ">
            <table className="w-full text-sm text-left rtl:text-right ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="p-1">
                            <div className="flex items-center">
                                No.
                            </div>
                        </th>
                        
                        <th scope="col" className="px-3 py-1">
                            Abonnement
                        </th>

                        <th scope="col" className="px-3 py-1">
                            Client
                        </th>
                        <th scope="col" className="px-3 py-1">
                            Equipe
                        </th>
                        <th scope="col" className="px-3 py-1">
                            Etat
                        </th>

                        <th scope="col" className="px-3 py-1">
                            Date
                        </th>
                          
                    </tr>
                </thead>
                <tbody>
                    {
                        Programs?.map((program, index) => {
                            return(
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-3 py-1">
                                        <span>{ index += 1 }</span>
                                    </td>
                                      
                                    <td className="px-3 py-1">
                                        { program.abonnement?.typeabonnement?.name}
                                    </td>
                                    <td className="px-3 py-1">
                                    { program.abonnement?.user?.typeabon?.name} <strong>{ program.abonnement?.user?.society }</strong> { program.abonnement?.user?.name}
                                    </td>
                                    <td className="px-3 py-1">
                                        { program.team?.name}
                                    </td>
                                    <td className="px-3 py-1">
                                        { program.isconfirm == 0 ? <span className="px-1 py-[2px] rounded text-slate-800 font-semibold bg-red-300">En attente</span> : <span className="px-1 py-[2px] rounded text-slate-800 font-semibold bg-blue-300">Fait</span>}
                                    </td>
                                    <td className="px-3 py-1">
                                        { new Date(program.date)?.toLocaleDateString("en-GB")}
                                    </td>
                                      
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
      </div>


        <div className='text-1xl mb-3 font-medium mt-5 pt-5  font30 extraBold'>Abonnements</div>

        <div className='bg-white shadow-xl  w-full rounded-md p-2 mb-5 pb-5'>
        <div className="relative max-h-[60vh] overflow-x-auto overflow-y-auto ">
            <table className="w-full text-sm text-left rtl:text-right ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="p-1">
                            <div className="flex items-center">
                                No.
                            </div>
                        </th>
                        
                        <th scope="col" className="px-3 py-1">
                            Abonnement
                        </th>

                        <th scope="col" className="px-3 py-1">
                            Client
                        </th>
                        <th scope="col" className="px-3 py-1">
                            Initié par
                        </th>
                        <th scope="col" className="px-3 py-1">
                            Confirmé
                        </th>

                        <th scope="col" className="px-3 py-1">
                            Date debut
                        </th>

                        <th scope="col" className="px-3 py-1">
                            Date fin
                        </th>
                            
                    </tr>
                </thead>
                <tbody>
                    {
                        Abonnements?.map((abonnement, index) => {
                            return(
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-3 py-1">
                                        <span>{ index += 1 }</span>
                                    </td>
                                        
                                    <td className="px-3 py-1">
                                        { abonnement?.typeabonnement?.name}
                                    </td>
                                    <td className="px-3 py-1">
                                    { abonnement?.user?.typeabon?.name} <strong>{ abonnement?.user?.society }</strong> { abonnement?.user?.name}
                                    </td>
                                    <td className="px-3 py-1">
                                        { abonnement.created?.name}
                                    </td>
                                    <td className="px-3 py-1">
                                         <span className= { abonnement.status == "Demande" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-red-300" : abonnement?.status == "Abonnement" ? "px-1 py-[2px] rounded text-slate-800 font-semibold bg-blue-300" : "px-1 py-[2px] rounded text-slate-800 font-semibold bg-green-300"}> { abonnement?.status}  </span>
                                    </td>
                                    <td className="px-3 py-1">
                                        { new Date(abonnement.datestart)?.toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="px-3 py-1">
                                        { new Date(abonnement.dateend)?.toLocaleDateString("en-GB")}
                                    </td>
                                        
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
        </div>

    </>
  )
}
const Statistics = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
  margin-top: 30px;
`;

export default Dashboard