import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Assets
import HomeBanner from "../../assets/img/banners/HomeBanner.png";
import HomeUse1 from "../../assets/img/banners/HomeUse1.jpg";
import HomeUse2 from "../../assets/img/banners/HomeUse2.jpg";
import HomeUse3 from "../../assets/img/banners/HomeUse3.jpg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { Link } from "react-scroll";

export default function Banner() {
  const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <Wrapper id="home" className="container overflow-hidden flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60"><span className="text-[#7620ff]">MrClean</span> votre partenaire proprété.</h1>
          <HeaderP className="font13 semiBold">
            Nous nous engageaons a préserver l'environnemnt en facilittant la récupération et le recyclage de vos déchet. 
            Notre mission est de rendre la gestion des déchets simple, efficace et accessible à tous.
          </HeaderP>
          <BtnWrapper>
            <Link activeClass="active" className=" pointer bg-[#7620ff] px-3 py-2 rounded  hover:bg-violet-700 hover:text-slate-100 text-slate-100 "  to="about" spy={true} smooth={true} offset={-80}>
              En savoir plus
            </Link>
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HomeBanner} alt="office" style={{zIndex: 9, width:"426px"}} />
          <QuoteWrapper className="flexCenter bg-whit radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p>
                <Img className="radius8" src={HomeUse1} alt="office" style={{zIndex: 9}} />
              </p>
            </div>
          </QuoteWrapper>

          <QuoteWrapperTop className="flexCenter bg-whit radius8">
            <div>
              <p>
                <Img className="radius8" src={HomeUse3} alt="office" style={{zIndex: 9}} />
              </p>
            </div>
          </QuoteWrapperTop>

          <QuoteWrapperBottom className="flexCenter bg-whit radius8">
            <div>
              <p>
                <Img className="radius8" src={HomeUse2} alt="office" style={{zIndex: 9}} />
              </p>
            </div>
          </QuoteWrapperBottom>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;

const QuoteWrapperBottom = styled.div`
  position: absolute;
  bottom: -7rem;
  right: -7rem;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    right: -7rem;
  }
  @media (max-width: 560px) {
    bottom: -7rem;
  }
`;

const QuoteWrapperTop = styled.div`
  position: absolute;
  top: -7rem;
  right: -7rem;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 760px) {
    right: -7rem;
  }
  @media (max-width: 560px) {
    top: 0rem;
  }

  @media (max-width: 924px) {
    top: 1rem;
  }


`;


