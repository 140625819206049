import React, { useEffect, useState } from 'react';
import loginBanner from "../../assets/img/banners/HomeBannerd.jpg";
import LogoIcon from "../../assets/svg/Logo";
import { Link } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
  const [TypeabonId , setTypeabonId] = useState("")
  const [Society , setSociety] = useState("")
  const [Name , setName] = useState("")
  const [Tel , setTel] = useState("")
  const [Email , setEmail] = useState("")
  const [Adress , setAdress] = useState("")
  const [Password , setPassword] = useState("")
  const [Confirm , setConfirm] = useState("")
  const [Error , setError] = useState("")
  const [Typeabons , setTypeabons] = useState([])

  const fetchTypeabons = async() => {
    let response = await fetch('https://apimrclean.onrender.com/typeabons/')
    response = await response.json()
    setTypeabons(response)
  }

  const Save = () => {
    if(TypeabonId){
      if(Password == Confirm)
      {
        let data = {
          name : Name,
          society : Society,
          tel : Tel,
          email : Email,
          password : Password,
          adress : Adress,
          typeabonId : TypeabonId,
          profileId : "f3843869-9376-4fb6-a5ae-4f695b232d15"
        }
        axios.post("users", data)
        .then((login) => {
          // localStorage.setItem("user", JSON.stringify(login.data.data));
          // localStorage.setItem("permissions", JSON.stringify(login.data.permissions ? login.data.permissions : []) );
          window.location.href="/login"
        }).catch(err => {
          setError(err.response?.data?.msg)
        })
      }else{
        setError("Les deux mot de passe sont differents")
      }
    }else{
      setError("Type abonnement est obigatoire")
    }
  }
  useEffect(() => {
    fetchTypeabons()
  }, [])

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="container">
          <div className="text-center mb-[50px]  mt-[50px] ">
            <div className="flex justify-center">
              <p className="pointer text-center flexNullCenter" to="/home">
                <LogoIcon />
                <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
                  MrClean
                </h1>
              </p>
            </div>
            <h3 className="text-2xl font-medium">
              Créer un compte
            </h3>
            <p className="font-extralight"><small>Enregistrez-vous pour avoir plus d'experience <Link to="/" className="ml-2 text-[#7620ff]">Visitez notre site</Link></small></p>
          </div>
          <div className="shadow px-[50px] py-[20px] rounded-lg">
             <div className="space-y-2">
                <div>
                  <label className="block mb-2 text-sm font-medium text-red-900 dark:text-white">{ Error }</label>
                </div>

                <div className="block md:flex justify-between">
                  <div>
                    <div className="mb-2 block">
                      <label for="abonementType" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Type d'abon</label>
                    </div>
                    <select id="abonementType" value={ TypeabonId } name="typeabonId" onChange={(e) => setTypeabonId(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Type d'abonné</option>
                      {
                        Typeabons.map((typeabon) => (
                          <option value={ typeabon.id}>{ typeabon.name }</option>
                        ))
                      }
                    </select>  
                  </div>

                  <div className="md:ml-1 md:w-[70%]">
                    <label for="society" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Abonné { Typeabons?.filter(val => val.id == TypeabonId)[0]?.name }</label>
                    <input type="text" id="society" value={ Society } onChange={ (e) => setSociety(e.target?.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                  </div>
                </div>

                <div>
                  <label for="adress" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adresse { Typeabons?.filter(val => val.id == TypeabonId)[0]?.name } </label>
                  <input type="text" id="adress" value={ Adress } onChange={ (e) => setAdress(e.target?.value)}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>

                <div>
                  <label for="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Personne de contact</label>
                  <input type="text" id="name" value={ Name } onChange={ (e) => setName(e.target?.value)}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                </div>

                <div className="block md:flex justify-between">
                  <div className="md:w-[49%]">
                    <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Numéro de téléphone</label>
                    <input type="text" id="phone" value={ Tel } onChange={ (e) => setTel(e.target?.value)}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                  </div>
              
                  <div className="md:w-[49%]">
                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input type="text" id="email" value={ Email } onChange={ (e) => setEmail(e.target?.value)}  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                  </div>
                </div>

                <div className="block md:flex justify-between">
                  <div className="md:w-[49%]">
                    <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mot de passe</label>
                    <input type="password" value={ Password } onChange={ (e) => setPassword(e.target?.value)} id="password" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                  </div>
                  <div className="md:w-[49%]">
                    <label for="confirmPassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirmer le mot de passe</label>
                    <input type="password" id="confirmPassword" value={ Confirm } onChange={ (e) => setConfirm(e.target?.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
                  </div>
                </div>

                <div className="w-full">
                    <button type='buttom' onClick={() => Save() } className="rounded text-sm font-bold p-[10px] text-slate-100 bg-[#7620ff] duration-300 hover:bg-[#7620cf]">Enregistrer</button>
                </div>
                <p className="text-right">
                  <small>Vous avez un compte?<Link to="/login" className="ml-2 text-[#7620ff]">Connectez-vous</Link></small>
                </p>
            </div>
          </div>
        </div>
        <div className="w-full hidden md:block h-screen">
          <div className="overflow-hidden w-full h-full">
            <img src={loginBanner} alt="auth banner" className="h-full w-full object-cover" />
          </div>
        </div>
      </div>
    </>
  )
}

export default Register