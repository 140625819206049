import React from 'react'
import { Outlet } from 'react-router-dom'
import {Asidebar, Header} from '../components/Admin/Nav'

const BaseAdmin = () => {
    return (
        <>
            <Asidebar/>
            <main className="main font14 w-screen md:ms-[230px] lg:w-[calc(100vw-230px)] h-screen overflow-x-hidden bg-slate-50 overflow-y-hidden">
                <Header/>
                <div className="px-[1%] h-full overflow-x-hidden  py-3 mb-10 pb-10">
                    <Outlet />
                <br />
                <br />
                <br />
                <br />
                <br />
                </div>
            </main>
        </>
    )
}

export default BaseAdmin