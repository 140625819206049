import React from "react";
import loginBanner from "../../../assets/img/banners/HomeBanner2.jpg";
                        
import { Tabs } from "flowbite-react";
import { LockClosedIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import InformationPersonnel from "./InformationPersonnel";
import PasswordForm from "./PasswordForm";

const ProfileContent = () => {
    return (
        <>
            <div className=" grid text-md grid-cols-1 lg:grid-cols-12 gap-2">
                <div className="px-[20px] max-h-[56vh] w-full lg:col-span-3 bg-white rounded-md">
                    <div className="flex justify-center px-2 py-5">
                        <div>
                            <div className="w-[120px] h-[120px] mb-2 shadow border-4 border-solid overflow-hidden border-sky-700 rounded-full">
                                <img src={loginBanner}className="w-full h-full object-cover" alt="profile" />
                            </div>
                            <p className="font-semibold text-center">Abikwanine aspen</p>
                            <p className="text-center">Super utilisateur</p>
                        </div>
                    </div>
                </div>
                
                <div className="px-[20px] w-full lg:col-span-9 bg-white rounded-lg">
                    <div className="px-2 py-5 ">

                        <div className="overflow-x-auto">
                            <Tabs aria-label="Full width tabs" style="fullWidth">
                                <Tabs.Item active title="Profile" className="font-semibold" icon={UserCircleIcon}>
                                    <InformationPersonnel/>
                                </Tabs.Item>
                                <Tabs.Item title="Mot de passe" icon={LockClosedIcon}>
                                    <PasswordForm/>
                                </Tabs.Item>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileContent;