import React, { useEffect, useState } from "react";
// Assets
import ListNav from "./ListNav";

const Asidebar = () => {

  return (
    <>
      <div className=" bg-sky-900 md:flex flex-col hidden justify-between shadow-[0_0_5px] h-screen w-[230px] fixed left-[0] top-[0]  z-[1] animate">
        <ListNav/>
      </div> 
    </>
  );
}

export default Asidebar;