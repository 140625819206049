import React from "react";
import styled from "styled-components";
// Components
import FaqBox from "../Elements/FaqBox";

export default function Faq() {
  return (
    <Wrapper id="faq">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Pourquoi choisir <span className="text-[#7620ff]">MrClean</span>?</h1>
            <p className="font13">
              Certainement, vous vous posez certaines questions en rapport avec la fiabilite de nos services.
              <br/>
              Nous  allons vous parvenir certaines de nos réflexions en rapport avec nos service et aussi notre fiabilité!
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <FaqBox
                title="Service fiable et ponctuel"
                text="Nous avons un service sur, consistant et respectueux des horaires prévues."
                textBr="Nos services sont performant, répond aux attentes des nos clients en termes de qualités."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <FaqBox
                title="Respect strict des réglementations écologiques"
                text="Nous suivons rigoureusement les lois, les réglements et les politiques 
                qui visent à protéger l'environnement, à promouvoir la durabilité et faire un pas vers un avenir plus vert."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <FaqBox
                title="Contribution active à la réduction de l'empreinte carbone"
                text="Nous faisons des actions concrètes et volontaires pour diminuer les émissions de gaz à 
                effet de serre liées à nos activités personnelles, professionnelles ou industrielles."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg mt-[50px]" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Notre mission</h1>
            <p className="font13">
                Vue notre mission de rendre la gestion des déchets simple, efficace et accessible à tous, nos clients en témoignent d'eux m&ecirc;me!
                <br/>
                Contactez-nous dès aujourd'hui pour en savoir plus sur nos services et faire un pas de plus vers un avenir plus vert!
            </p>
          </HeaderInfo>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;